<template>
  <div v-if="open" class="popover black--text text-xs-left" style="max-height: 200px; overflow-y: scroll;" ref="popover" id="captions-popover">
    <div style="font-size: 14px"> Captions </div>
    <div style="margin: 0 auto; text-align: left; margin-top: -10px;">
      <div v-for="o in options" @click="setCaptions(o)" class="cpointer" style="font-size: 12px; height: 30px; line-height: 30px; white-space: nowrap;">
        <span v-if="o == current" style="color: green">✓ </span><span v-else>&nbsp; </span>{{ o }}
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['current', 'options', 'open'],
  data() {
    return {
    };
  },
  methods: {
    setCaptions(option) {
      this.$emit('update', option)
    },
    openPopover() {
      this.open = true;
      document.addEventListener("click", this.handleClickOutside);
    },
    closePopover() {
      this.open = false;
      document.removeEventListener("click", this.handleClickOutside);
    },
    handleClickOutside(event) {
      const popover = this.$refs.popover;
      if (popover && !popover.contains(event.target)) {
        this.closePopover();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<style scoped>
.popover {
  /* position: absolute; */
  z-index: 100;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
}

</style>